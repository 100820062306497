import React from "react";
import { TsicommonCmsModelsRichNavigationLinkTmpl } from "@reactivated";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";
import sharedStyles from "../shared.module.scss";

export const RichNavigationLink = (
    props: TsicommonCmsModelsRichNavigationLinkTmpl,
) => {
    return (
        <a
            className={concatClassNames([
                sharedStyles.hoverEffect,
                styles.link,
            ])}
            href={props.url}
        >
            {props.title}
        </a>
    );
};
